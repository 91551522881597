
import { ImageItemFragment } from "@/__generated__/graphql";
import { useMobile } from "@lib/hooks/useMobile";
import { breakpoints, buttonPrimary, colors, imageLoaderResponsive, mediaQueries } from '@theme/lib';
import Image from "next/legacy/image";
import { ReactElement } from 'react';
import styled from 'styled-components';

const ImageWrapper = styled.div`
  background: transparent;
  grid-column: 1 / span 4;

  height: 267px;

  ${mediaQueries.sm} {
    height: 429px;
  }

  ${mediaQueries.md} {
    height: 459px;
  }

  ${mediaQueries.lg} {
    height: 510px;
  }

  height: 100%;
  width: 100%;

  .wrapper {
    object-fit: cover;
    img {
      border-radius: ${props => props.theme.config.componentRadius};
    }
    overflow: hidden;
    height: 100%;
    width: 100%;
  }

  .content {
    display: grid;
    align-items: center;
    text-align: center;
    height: 100%;
    width: 100%;
  }
  .content-block {
    position: relative;
    color: ${colors.white};
    padding: 3.75rem 2rem;

    ${mediaQueries.lg} {
      padding: 3.75rem 5.25rem;
    }
  }

  .title-block {
    font-size: 1.5rem;
    line-height: 1.65rem;
    font-weight: 400;

    ${mediaQueries.lg} {
      font-size: 2.25rem;
      line-height: 2.475rem;
    }
  }

  .btn-primary {
    ${buttonPrimary}
    margin-top: 1rem;
    font-size: 1rem;
    line-height: 1.2rem;
    font-weight: 400;

    ${mediaQueries.lg} {
      line-height: 1.35rem;
      font-size: 1.125rem;
    }
  }

`;

export type ImageItemProps = ImageItemFragment & {
  content?: ReactElement|null;
}

export default function ImageItem({ image, content }: ImageItemProps): ReactElement {
  const isMobile = useMobile();
  return <ImageWrapper>
    <div className="wrapper">
      <Image
        src={image.url}
        alt={''}
        layout="fill"
        objectFit="cover"
        objectPosition="center"
        sizes={`(min-width: ${breakpoints.lg}px) 25vw,
                (min-width: ${breakpoints.md}px) 50vw,
                100vw`}
        width={isMobile ? 310 : 592}
        height={isMobile ? 267 : 510}
        loader={imageLoaderResponsive(isMobile ? 310 : 592, isMobile ? 267 : 510, true)}
      />
      {content && (<div className={"content"}>{content}</div>)}
    </div>
  </ImageWrapper>;
}
