import { ProjectInDetailSliderFragment } from '@/__generated__/graphql';
import CallToActionItem from '@components/SubParagraphs/CallToActionItem';
import ImageItem from '@components/SubParagraphs/ImageItem';
import QuoteItem from '@components/SubParagraphs/QuoteItem';
import VideoItem, { VideoItemEventsProps } from '@components/SubParagraphs/VideoItem';
import { GridContainer, colors, mediaQueries } from '@theme/lib';
import { FunctionComponent, ReactElement, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { SwiperProps, SwiperSlideProps } from 'swiper/react';


import { SwiperModule } from 'swiper/types';

const StyledBackground = styled.div`
  height: 100%;
  width: 100%;
  background: ${(props) => props.theme.colors.accordionTabBgr};
  overflow: hidden;
  margin-bottom: 3rem;

  ${mediaQueries.lg} {
    margin-bottom: 4rem;
  }
`;

const StyledGridContainer = styled(GridContainer)`
  margin-bottom: 0;
  ${mediaQueries.lg} {
    margin-bottom: 0;
  }
`;

const ProjectInDetailSliderWrapper = styled.div`
  width: 100%;
  grid-column: 1 / span 5;
  ${mediaQueries.lg} {
    grid-column: 1 / span 10;
  }

  margin-top: 2rem;
  margin-bottom: 2rem;

  ${mediaQueries.md} {
    margin-top: 3rem;
    margin-bottom: 2rem;
  }

  ${mediaQueries.lg} {
    margin-top: 3rem;
    margin-bottom: 2rem;
  }

  .swiper {
    overflow: visible;
  }

  .slider-wrapper {
    margin: 0 auto;
  }

  .slider-title-block {
    color: ${colors.white};
    float: left;
  }

  .top-block {
    ${mediaQueries.md} {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
  }

  .slider-navigation-block {
    display: none;

    button {
      background: none;
      border: none;
      height: auto;
      width: auto;
      cursor: pointer;
      margin: 0;
      padding: 0;
    }

    ${mediaQueries.md} {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .slider-navigation-left,
    .slider-navigation-right {
      display: inline-block;
      height: 48px;
      width: 48px;
    }

    .slider-navigation-right {
      margin-left: 1.75rem;
    }
  }

  .before-items-block {
    clear: both;
  }

  .items-block {
    margin-top: 1.625rem;
    clear: both;
  }



  .swiper-button-disabled {
    cursor: default;
    opacity: 0.5;
  }

  .items-inner-block {
    height: 267px;

    ${mediaQueries.sm} {
      height: 429px;
    }

    ${mediaQueries.md} {
      height: 459px;
    }

    ${mediaQueries.lg} {
      height: 510px;
    }

    ul {
      list-style: none;
    }

    .swiper-wrapper {
      width: 100%;
    }

    .swiper {
      width: 100%;
    }

    .swiper-slide {
      width: 316px !important;

      ${mediaQueries.md} {
        width: 532px !important;
      }

      ${mediaQueries.lg} {
        width: 592px !important;
      }
    }

    .video-item-slide {
      width: 316px !important;

      ${mediaQueries.md} {
        width: 815px !important;
      }

      ${mediaQueries.lg} {
        width: 905px !important;
      }
    }
  }

  ${mediaQueries.xl} {
    .slider-wrapper {
      width: 100%;
    }
  }
`;

const StyledPagination = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-top: 1.5rem;

  span.swiper-pagination-bullet {
    cursor: pointer;
    height: 1rem;
    width: 1rem;
    border-radius: 0.25rem;
    opacity: 1;
    background-color: ${colors.seagrassDark};
    margin: 0 !important;

    ${mediaQueries.lg} {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  span.swiper-pagination-bullet-active {
    background-color: ${colors.parakeet};
  }

  ${mediaQueries.lg} {
    gap: 0.75rem;
    margin-top: 2.3rem;
  }
`;

export default function ProjectInDetailSlider({
  id,
  title,
  items,
}: ProjectInDetailSliderFragment): ReactElement {
  const youtubeRefs = useRef([] as Array<VideoItemEventsProps>);

  // Dynamically load swiper.
  const [swiperModules, setSwiperModules] = useState<SwiperModule[]>([]);
  const [Swiper, setSwiper] = useState<FunctionComponent<SwiperProps> | null>(null);
  const [SwiperSlide, setSwiperSlide] = useState<FunctionComponent<SwiperSlideProps> | null>(null);

  useEffect(() => {
    const getSwiperModules = async () => {
      const modules = await import('swiper');
      const { Navigation, Pagination } = await import('swiper/modules');
      const swiperComponents = await import('swiper/react');

      setSwiperModules([Pagination, Navigation]);
      setSwiper(swiperComponents.Swiper);
      setSwiperSlide(swiperComponents.SwiperSlide);
    };

    getSwiperModules();
  }, []);
  // Dynamically load swiper - End.

  const onTransitionStart = () => {
    for (const ref of youtubeRefs.current) {
      if (ref) {
        ref.pauseVideo();
      }
    }
  };
  if (swiperModules.length > 0 && Swiper && SwiperSlide) {
    return (
      <StyledBackground>
        <StyledGridContainer>
          <ProjectInDetailSliderWrapper id={`project-in-detail-slider__${id}`}>
            <div className="slider-wrapper">
              <div className="top-block">
                <div className="slider-title-block"><h2>{title}</h2></div>
                <div className="slider-navigation-block">
                  <button className={`slider-navigation-left slider-navigation-left-${id}`}>
                    <img loading="lazy" src="/ProjectInDetailSlider/arrow-left.svg" alt="Previous slide" />
                  </button>
                  <button className={`slider-navigation-right slider-navigation-right-${id}`}>
                    <img loading="lazy" src="/ProjectInDetailSlider/arrow-right.svg" alt="Next slide" />
                  </button>
                </div>
              </div>
              <div className="before-items-block"></div>
              <div className="items-block">
                <div className="items-inner-block">
                  <Swiper
                    navigation={{
                      nextEl: `.slider-navigation-right-${id}`,
                      prevEl: `.slider-navigation-left-${id}`,
                    }}
                    spaceBetween={16}
                    slidesPerView="auto"
                    pagination={{ clickable: true, el: `.carousel__pagination-${id}` }}
                    modules={swiperModules}
                    onTransitionStart={onTransitionStart}
                    wrapperTag="ul"
                    breakpoints={{
                      1250: {
                        spaceBetween: 32,
                      },
                    }}
                  >
                    {items.map((item, key) => {
                      if (item.__typename === 'CallToActionItemParagraph') {
                        return (
                          <SwiperSlide key={key} tag="li">
                            <CallToActionItem {...item} key={key} />
                          </SwiperSlide>
                        );
                      }
                      if (item.__typename === 'ImageItemParagraph') {
                        return (
                          <SwiperSlide key={key} tag="li">
                            <ImageItem {...item} key={key} />
                          </SwiperSlide>
                        );
                      }
                      if (item.__typename === 'QuoteItemParagraph') {
                        return (
                          <SwiperSlide key={key} tag="li">
                            <QuoteItem {...item} key={key} />
                          </SwiperSlide>
                        );
                      }
                      if (item.__typename === 'VideoItemParagraph') {
                        return (
                          <SwiperSlide key={key} tag="li" className="video-item-slide">
                            <VideoItem
                              {...item}
                              key={key}
                              ref={(el: VideoItemEventsProps) : void => {youtubeRefs.current[key] = el}}
                            />
                          </SwiperSlide>
                        );
                      }
                    })}
                  </Swiper>
                </div>
              </div>
              <StyledPagination className={`carousel__pagination carousel__pagination-${id}`} />
            </div>
          </ProjectInDetailSliderWrapper>
        </StyledGridContainer>
      </StyledBackground>
    )
  }
  else {
    return <>Loading...</>
  }
}
